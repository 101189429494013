<template>
<div id="EditEvent">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<!-- edit form -->
<v-form @submit.prevent="UpdateEvent">
<v-container class="pa-5">
<v-card class="pa-2 mt-5 elevation-5">
  <v-card-title>
    <h3>Edit Event</h3>
  </v-card-title>
  <v-card-text class="pa-2">
    <v-row>
      <!-- Edit form -->
      <!-- file upload -->
      <v-col cols="12" sm="6" md="4">
        <v-file-input v-model="files" :disabled="Upcoming" color="deep-purple accent-4" single-line label="Upload Image"
        prepend-icon="mdi-google-photos" accept="image/*">
        </v-file-input>
      </v-col>
      <!-- other fields -->
      <v-col cols="12" sm="6" md="4">
          <v-text-field type="tel" label="Date of Event" :error="$v.DOE.$error" :error-messages="DOEErrors" v-mask="mask" 
          @input="$v.DOE.$touch()" v-model="DOE" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
          <v-text-field label="Title" :error="$v.Title.$error" :error-messages="TitleErrors"
          v-model.trim="Title" @input="$v.Title.$touch()" required></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="py-0">
          <p class="subtitle-1 mb-1">Description: </p>
          <ckeditor tag-name="textarea" :editor="editor" :disabled="Upcoming"
          :config="editorConfig" v-model="Description" required @ready="assignData(EventData)"></ckeditor>
          <p v-html="wordCountMsg" class="subtitle-1 mb-1"></p>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="py-0 mt-0">
        <v-checkbox v-model="Upcoming" label="Upcoming" class="py-0 mt-0"></v-checkbox>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions class="px-3 pt-0" >
      <v-btn type="submit" class="elevation-10 success pt-0" :disabled="disbtn">Update Event</v-btn>
      <v-btn @click="$router.go(-1)" class="elevation-10 secondary">back</v-btn>
  </v-card-actions>

  <!-- show overlay when add item -->
  <v-fade-transition>
  <v-overlay absolute :value="disbtn">
    <v-progress-circular color="yellow" indeterminate width="15" size="100"></v-progress-circular>
  </v-overlay>
  </v-fade-transition>

</v-card>
</v-container>
</v-form>

</div>
</template>

<script>
// imports
import { required, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";
// ckeditor
// Since we're building CKEditor from source, we use the source version of ClassicEditor.
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import StrikeThrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';

// scripts
export default {

    name: 'EditEvent',

    created(){this.$store.dispatch('getEvents');},

    data(){return{
        
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,

        // student flags
        Title: '',Description: '',DOE: '',mask: '##-##-####',files: null,selectedEventFromLoop: [],imgURL: '',Upcoming: false,

        // ckeditor
        editor: ClassicEditor,wordCountMsg: null,
        editorConfig: {
            plugins: [
                EssentialsPlugin,BoldPlugin,ItalicPlugin,LinkPlugin,
                ParagraphPlugin,StrikeThrough,Heading,Font,Subscript,
                Superscript,Underline,Code,Alignment,WordCount
            ],

            wordCount: {
              onUpdate: status => { 
                  let words = status.words;
                  this.disbtn = words > 1500 ? true : false;
                  let limit = 1500 - words;
                  let count = limit >= 0 ? limit : 0;
                  let ongoing = `Max-Words: 1500, Left: ${count}`;
                  let limitexceed = `<span style="color: #ea1111">Exceeded limit, Max-Words: 1500</span>`;
                  this.wordCountMsg = words <= 1500 ? ongoing : limitexceed;
              }
            },

            toolbar: {
                items: [
                    'bold','italic','underline','|','undo','redo','|',
                    'strikethrough','link','superscript','subscript','code','|','heading','|',
                    'fontFamily','fontSize','fontColor','fontBackgroundColor','|',
                    'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify',
                ]
            },

        },
    }},

    methods:{

      // add event
      async UpdateEvent(){
        // vars
        const dateObj = new Date();
        // blank validation
        if(!this.BlankValidation()){return false;}this.disbtn = true;
        // create a unique slug
        let titleSlug = this.Slugify(this.Title) + this.DOE + dateObj.getMilliseconds() + '-kalalaya-academy-' +
                        Math.floor(Math.random() * 107477);  + dateObj.getSeconds().toString();

        // clear unwanted fileds
        if(this.Upcoming == true){this.files = null, this.Description = ''}
        // actual update statement
        // get document where student id equals selected one
        let status = this.Upcoming == true ? 'Upcoming' : 'Past';
        await firebase.firestore().collection("events").where("eventslug", "==", this.selectedEventFromLoop.eventslug).get().then(
         (doc) => {
            let imgurlupdate = '';
            // if past status updated to upcoming, just nuke all other fields  
            if(this.selectedEventFromLoop.status == 'Past' && status != 'Past'){
              this.files = null;this.Description = '';imgurlupdate = '';
              if(this.selectedEventFromLoop.imageUrl){
                firebase.storage().refFromURL(this.selectedEventFromLoop.imageUrl).delete();
              }
            }else if(this.selectedEventFromLoop.status == 'Past' && status == 'Past')
            {
              if(this.selectedEventFromLoop.imageUrl)
                imgurlupdate = this.selectedEventFromLoop.imageUrl
            }

            // update all datas(sorry for cramping it up)
            firebase.firestore().collection("events").doc(doc.docs[0].id).update({
                "eventslug": titleSlug,"title": this.Title,"description": this.Description,
                "DOE": this.DOE,"status": status,"imageUrl": imgurlupdate
            }).then((succ) => {
                  // image upload
                  this.ImageUpload(doc.docs[0].id);
                  
            }).catch((err) => {
                // spit out error
                this.disbtn = false;   
                this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
            }); 
            
         }).catch((err) => {
            // spit out error
            this.disbtn = false;   
            this.notificationService("Server Error, Try After Some Time Or Call Developer", "red darken-1");
         }); 

      },

      // image upload function
      ImageUpload(key){

        // vars
        let imageFile;let imageFileName;let ext;let imageUrl;;let task;
        // check if image exists, run Final
        if(this.files == null) { return this.Final(); }
        // actual add file
        imageFile = this.files;
        // get filename
        imageFileName = imageFile.name;
        // get extention of filename
        ext = imageFileName.slice(imageFileName.lastIndexOf('.'));
        // get a reference to store image in firebase storage
        let storageRef = firebase.storage().ref('events/' + key + ext);
        // upload image
        storageRef.put(imageFile).then((snapshot) => {
          // get download url and update db
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            firebase.firestore().collection('events').doc(key).update({ imageUrl: downloadURL})
            .then((res) => { this.Final(); });
          });
        }).catch((err) => {
           // spit out error
          this.disbtn = false;   
          this.notificationService("Server Error, Image Upload Failed, Try After Some Time", "red darken-1");
        });
   
      },

      // run after all
      Final(){
          this.ResetFields(); 
          this.$store.dispatch('getEvents');
          this.notificationService("Event Updated SuccessFully!", "success");
          this.disbtn = false;   
          setTimeout(()=>{ this.$router.push('/events'); }, 1000); 
      },

      // assign curresponsing data to student
      assignData(selectedData){
        if(selectedData.length > 0){
            selectedData.forEach((sd) => {
            this.Title = sd.title, this.Description = sd.description,
            this.DOE = sd.DOE,this.imgURL = sd.imageUrl,
            this.Upcoming = sd.status == 'Upcoming' ? true : false,
            this.selectedEventFromLoop = sd;
        });}else{return null}
      },

      // reset fields
      ResetFields(){     
        this.files = null;this.Title = '';this.Description = '';this.DOE = '';this.Upcoming = false;
        this.$v.$reset();
      },

      // check if all fileds are empty   
      BlankValidation(){
       if(this.Upcoming == false){
            if(this.files != null){
             if(this.files.size / 1024 >= 2048){this.notificationService("File Should be Under 2MB!", "red darken-1");return false;}
            }
            if(this.selectedEventFromLoop.imageUrl){
              if(this.Title == '' || this.Description == '' || this.DOE == '')
              {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
            }else{
              if(this.files == null || this.Title == '' || this.Description == '' || this.DOE == '')
              {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
            }
        }else{
            if(this.Title == '' || this.DOE == '')
            {this.notificationService("Fill All Fields!", "red darken-1"); return false;}else{return true;}
        }
      },
      
      // error pusher for vuelidate   
      ErrorPusher(basic, extra, msg){
        const errors = []
        if (!basic) return errors
        !extra && errors.push(msg);
        return errors;
      },

      // notification service
      notificationService(msg, type){
        this.notificationType = type;
        this.notificationMsg = msg;
        this.notificationToggle = true;
      },

      // slugify title (copied from medium :-D)
      Slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
      },

    },

     // vue-the-mask related stuff
    directives: { mask },

    // validations
    validations: {
        Title: { required }, 
        DOE: { required, minLength: minLength(10) },
    },

    computed: {

      EventSlug(){         
          return this.$route.params.eventslug != null ? this.$route.params.eventslug : null;
      },

      EventData(){
        if(this.EventSlug != null) {
            let data =  this.$store.getters.GetEventWithSlug(this.EventSlug);
            return data.length != 0 ? data : this.$router.push("/events");
        }else{ return this.$router.push("/events"); }             
      },
  
      // basically in computed, i added validations  
      // sorry, i cant find a way to make this even smaller : -D
      TitleErrors () { return this.ErrorPusher(this.$v.Title.$dirty, this.$v.Title.required, 'Title is Required'); },
      DOEErrors () { return this.ErrorPusher(this.$v.DOE.$dirty, this.$v.DOE.minLength, 'Date Of Event is Required (dd-mm-yyyy)'); },
      
    }

}
</script>
